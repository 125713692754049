import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  CreatableSelect,
  InputAsyncSelect,
  InputDate,
  InputMoney,
  InputNumber2,
  InputSelect,
  InputText,
} from '~/components/NovosInputs';
import Separator from '~/components/Separator';
import ToggleDefault from '~/components/ToggleDefault';
import { useEntradaNFE } from '~/pages/EntradaNFE/EntradaNFEContext';
import { Perfil, Serie } from '~/pages/EntradaNFE/protocols';
import api from '~/services/api';
import { getUserData } from '~/services/user';

type Select = {
  value: string | number;
  label: string;
};

export const Capa: React.FC = () => {
  const {
    changeLoja,
    changePerfil,
    changeFornecedor,
    setSerie,
    produtos,
    perfil,
    canUpdate,
    formNfe,
    perfis,
    especies,
    lojasAtivas,
    codXml,
    validadorProdutorRural,
    setDisabledFormItens,
    blockSerieNF,
    setPerfil,
    loja,
  } = useEntradaNFE();

  const [series, setSeries] = useState<Select[]>([]);
  const user = getUserData();

  const {
    setValue,
    getValues,
    formState,
    watch,
    register,
    control,
    clearErrors,
    reset,
  } = formNfe;
  const { isDirty, errors } = formState;

  useEffect(() => {
    getSeries();
  }, []);

  useEffect(() => {
    const createOption = (reg: any) => {
      return {
        label:
          reg.cod_loja < 10
            ? `0${reg.cod_loja} - ${reg.des_loja} (${reg.des_cidade})`
            : `${reg.cod_loja} - ${reg.des_loja} (${reg.des_cidade})`,
        value: reg.cod_loja,
        des_loja: reg.des_loja,
        uf: reg.des_uf,
        cod_loja: reg.cod_loja,
        tipo_regime: reg.tipo_regime,
        ...reg,
      };
    };
    (async () => {
      if (user.loja) {
        const { data } = await api.get(`/lojas/${user.loja}`);
        if (data.success) {
          const lojaFormatted = createOption(data.data);
          setValue('cod_loja', lojaFormatted);
          changeLoja(lojaFormatted);
        }
      }
    })();
  }, [user.loja]);

  const getSeries = async () => {
    const res = await api.get('/entrada-nfe/serie', {
      params: {
        cod_loja: loja.cod_loja,
      },
    });
    const { data, success, message } = res.data;
    if (!success) {
      throw new Error(message);
    }
    const regs = data.map((item: Serie) => {
      return {
        value: item.num_serie,
        label: `${item.num_serie}`,
        num_serie: item.num_serie,
      };
    });
    setSeries(regs);
  };

  const watchFlgConfirmar = watch('flg_confirmar');
  const watchCodPessoa = watch('cod_pessoa');
  const watchCodPerfil = watch('cod_perfil');

  useEffect(() => {
    let isDisabled = true;
    let placeholder = '';
    if (codXml !== undefined) {
      placeholder = 'Não é possível adicionar produtos ao XML importado';
    } else if (!watchCodPessoa || !watchCodPessoa.value) {
      placeholder = 'É necessário selecionar o Fornecedor.';
    } else if (!watchCodPerfil || !watchCodPerfil.value) {
      placeholder = 'É necessário selecionar o Perfil.';
    } else {
      isDisabled = false;
      placeholder = '';
    }
    setDisabledFormItens({
      isDisabled,
      placeholder,
    });
  }, [codXml, watchCodPessoa, watchCodPerfil]);

  const onChangedFornecedor = (selected: any) => {
    const { label, des_uf }: any = selected;
    if (!des_uf && label) {
      const codPessoa = getValues('cod_pessoa');
      setValue('cod_pessoa', codPessoa);
      toast.warning(
        `Endereço incompleto, atualize o cadastro do parceiro para continuar.`,
      );
    } else {
      validadorProdutorRural({
        fornecedor: selected,
        perfil: undefined,
      });
      setValue('cod_perfil', '');
      setValue('cod_pessoa', selected);
      changeFornecedor(selected);
      clearErrors('cod_pessoa');
    }
  };
  useEffect(() => {
    if (perfil.tipo_emissao === 1 && series.length === 1)
      setValue('cod_serie', series[0]);
  }, [perfil]);

  return (
    <>
      <Separator labelText="Capa da Nota" sidePadding="0 0" />

      <Row>
        <Col md={6} sm={12}>
          <InputSelect
            label="Loja"
            placeholder="Selecione..."
            name="cod_loja"
            register={register}
            isError={!!errors.cod_loja}
            control={control}
            options={lojasAtivas}
            disabled={!canUpdate || (canUpdate && produtos.length > 0)}
            changeSelected={(selected) => {
              changeLoja(selected);
              setValue('cod_loja', selected);
            }}
          />
        </Col>
        <Col md={5} sm={12}>
          <InputAsyncSelect
            label="Fornecedor *"
            maxLength={50}
            placeholder="Selecione..."
            name="cod_pessoa"
            register={register}
            isError={!!formState.errors.cod_pessoa}
            control={control}
            disabled={
              !canUpdate ||
              (canUpdate && !getValues('cod_loja')) ||
              (canUpdate && produtos.length > 0)
            }
            changeSelected={onChangedFornecedor}
            api={{
              route: '/nf-outras-operacoes/fornecedor',
              method: 'get',
              fildDescriptionForValue: 'cod_pessoa',
              parenthesesAroundLastLabel: true,
              fields: ['cod_fornecedor', 'des_fornecedor', 'num_cpf_cnpj'],
            }}
          />
        </Col>
        <Col md={1} sm={12}>
          <ToggleDefault
            labelText="A Confirmar?"
            setChecked={!!watchFlgConfirmar}
            disabled={!canUpdate}
            onSwitch={() => {
              clearErrors('dta_entrada');
              setValue('flg_confirmar', !watchFlgConfirmar);
            }}
          />
        </Col>

        <Col md={3} sm={12}>
          <InputSelect
            label="Perfil *"
            id="DropDownWidth"
            ListaWidth="650px"
            maxLength={50}
            placeholder="Selecione..."
            name="cod_perfil"
            register={register}
            isError={!!errors.cod_perfil}
            control={control}
            options={perfis}
            autoComplete="off"
            disabled={!canUpdate || (canUpdate && produtos.length > 0)}
            changeSelected={(selected: any) => {
              if (
                selected.flg_gera_financeiro &&
                (selected.cod_categoria === null || selected.cod_cc === null)
              ) {
                toast.warn(
                  `Dados financeiros do perfil '${selected.cod_perfil} - ${selected.des_perfil}' precisam ser corretamente preenchidos. Vá até ao cadastro de perfil, e complete seu cadastro.`,
                );
                setValue('cod_perfil', '');
                setPerfil({} as Perfil);
                return;
              }
              changePerfil(selected);
              setValue('cod_perfil', selected);
              clearErrors('cod_perfil');
              clearErrors('cod_serie');
            }}
          />
        </Col>
        <Col md={1} sm={12}>
          {perfil.tipo_operacao === 0 && perfil.tipo_emissao === 1 ? (
            <InputSelect
              label="Série *"
              maxLength={50}
              placeholder="Selecione..."
              name="cod_serie"
              register={register}
              autoComplete="off"
              isError={!!errors.cod_serie}
              control={control}
              options={series}
              disabled={!canUpdate || blockSerieNF}
              changeSelected={(selected) => {
                setSerie(selected);
                setValue('cod_serie', selected);
                clearErrors('cod_serie');
              }}
            />
          ) : (
            <InputText
              label="Série *"
              name="cod_serie"
              placeholder="Informe a série"
              register={register}
              isError={!!errors.cod_serie}
              disabled={!canUpdate || blockSerieNF}
              onKeyDown={(e) => {
                if (e.key === ' ' || e.code === 'Space') {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => {
                e.preventDefault();
                const pastedText = e.clipboardData.getData('text');
                const modifiedText = pastedText.replace(/\s+/g, '');
                const inputElement = e.target as HTMLInputElement;
                inputElement.value = modifiedText;
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = e.target;
                setSerie(value);
              }}
              maxLength={8}
            />
          )}
        </Col>

        <Col md={2} sm={12}>
          <InputSelect
            label="Espécie *"
            maxLength={50}
            ListaWidth="520px"
            placeholder="Selecione..."
            name="des_especie"
            register={register}
            autoComplete="off"
            isError={!!errors.des_especie}
            control={control}
            options={especies}
            disabled={
              !canUpdate || codXml !== undefined || perfil.tipo_emissao === 1
            }
            changeSelected={(selected) => {
              setValue('des_especie', selected);
              clearErrors('des_especie');
            }}
          />
        </Col>
        <Col md={2} sm={12}>
          <InputNumber2
            label="Nº NF *"
            control={control}
            max={9999999999}
            maxLength={10}
            placeholder="0"
            name="num_nf"
            register={register}
            disabled={
              !canUpdate ||
              (canUpdate && perfil.tipo_emissao === 1) ||
              codXml !== undefined
            }
            isError={!!errors.num_nf}
          />
        </Col>
        <Col md={2} sm={12}>
          <InputDate
            register={register}
            label="Emissão *"
            name="dta_emissao"
            control={control}
            disabled={!canUpdate}
            isError={!!errors.dta_emissao}
            typeError={formState.errors.dta_emissao?.message}
          />
        </Col>
        <Col md={2} sm={12}>
          <InputDate
            label="Entrada *"
            register={register}
            name="dta_entrada"
            control={control}
            disabled={!canUpdate}
            isError={!!errors.dta_entrada}
            typeError={formState.errors.dta_entrada?.message}
          />
        </Col>

        <Col md={2} sm={12}>
          <InputMoney
            label="Total NF *"
            placeholder="0,00"
            min={0}
            decimals={2}
            name="val_total_nf"
            register={register}
            disabled={!canUpdate}
            isError={!!errors.val_total_nf}
            control={control}
            showIcon
          />
        </Col>
        <Col md={2} sm={12}>
          <InputMoney
            label="BC ICMS OP"
            placeholder="0,00"
            min={0}
            decimals={2}
            name="val_total_bc"
            register={register}
            disabled={!canUpdate}
            isError={!!errors.val_total_bc}
            control={control}
            showIcon
          />
        </Col>
        <Col md={2} sm={12}>
          <InputMoney
            label="ICMS OP"
            placeholder="0,00"
            min={0}
            decimals={2}
            name="val_total_icms"
            register={register}
            disabled={!canUpdate}
            isError={!!errors.val_total_icms}
            control={control}
            showIcon
          />
        </Col>
        <Col md={2} sm={12}>
          <InputMoney
            label="BC ICMS ST"
            placeholder="0,00"
            min={0}
            decimals={2}
            name="val_total_bc_st"
            register={register}
            disabled={!canUpdate}
            isError={!!errors.val_total_bc_st}
            control={control}
            showIcon
          />
        </Col>
        <Col md={2} sm={12}>
          <InputMoney
            label="ICMS ST"
            placeholder="0,00"
            min={0}
            decimals={2}
            name="val_total_icms_st"
            disabled={!canUpdate}
            register={register}
            isError={!!errors.val_total_icms_st}
            control={control}
            showIcon
          />
        </Col>
        <Col md={2} sm={12}>
          <InputMoney
            label="FCP ST"
            placeholder="0,00"
            min={0}
            decimals={2}
            name="val_total_st_fcp"
            register={register}
            disabled={!canUpdate}
            isError={!!errors.val_total_st_fcp}
            control={control}
            showIcon
          />
        </Col>
        <Col md={2} sm={12}>
          <InputMoney
            label="Venda Varejo"
            placeholder="0,00"
            min={0}
            decimals={2}
            name="val_total_venda_varejo"
            register={register}
            disabled={!canUpdate}
            isError={!!errors.val_total_venda_varejo}
            control={control}
            showIcon
          />
        </Col>
        <Col md={2} sm={12}>
          <InputMoney
            label="Total IPI"
            placeholder="0,00"
            min={0}
            decimals={2}
            name="val_total_ipi"
            register={register}
            disabled={!canUpdate}
            isError={!!errors.val_total_ipi}
            control={control}
            showIcon
          />
        </Col>
      </Row>
    </>
  );
};
