import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import { useEntradaNFE } from '~/pages/EntradaNFE/EntradaNFEContext';

import { InputDate, InputMoney, InputSelect } from '~/components/NovosInputs';
import Separator from '~/components/Separator';
import { ButtonItens } from './styles';
import { FaEraser, FaSync } from 'react-icons/fa';
import { differenceInDays, format, isBefore, parseISO } from 'date-fns';
import clearForm from './clearForm.json';
import { toast } from 'react-toastify';
import { CalcDataCondicao } from '~/pages/DevolucaoESaidaNFDeFornecedor/functions/Procedures';
import api from '~/services/api';
import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';
import Swal from 'sweetalert2';
import { deleteItens, insertOrUpdate } from '~/utils/masterDetail';
import { nanoid } from 'nanoid';
import moment from 'moment';
import { Parcela } from '~/pages/EntradaNFE/protocols';
import { compareArrays } from './utils/compareArrays';
import { conditions } from './services';
import { calculateTotalNf } from './utils/calculateTotalNf';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import { IoMdAddCircleOutline } from 'react-icons/io';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export const FormParcelas: React.FC = () => {
  const [finalizadoras, setFinalizadoras] = useState<any[]>([]);

  const {
    formParcela,
    formNfe,
    canUpdate,
    uuidParcela,
    setUuidParcela,
    masterDetail,
    setMasterDetail,
    produtos,
    parcelas,
    setParcelas,
    xmlImportado,
    subTotais,
  } = useEntradaNFE();

  const focusInputByName = (inputName: string) => {
    const inputElement = document.querySelector<HTMLInputElement>(
      `input[name="${inputName}"]`,
    );
    if (inputElement) inputElement.focus();
  };

  useEffect(() => {
    (async () => {
      const res = await api.get('/finalizadora');
      const { data, success } = res.data;
      if (success) {
        const Finalizadoras = data.map((item: any) => {
          return {
            ...item,
            label: item.des_finalizadora,
            value: item.cod_finalizadora,
          };
        });
        setFinalizadoras(Finalizadoras);
      }
    })();
  }, []);

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState,
    setError,
  } = formParcela;

  useEffect(() => {
    if (parcelas.length !== 0) {
      const totalParcelas = parcelas.reduce(
        (acc, parcela: any) => acc + parcela.val_parcela,
        0,
      );
      const resultado = subTotais.valTotNfItem - totalParcelas;
      setTimeout(() => {
        setValue(
          'val_parcela',
          formatCurrencyAsText(resultado > 0 ? resultado : 0),
        );
      }, 10);
    } else {
      setTimeout(() => {
        setValue('val_parcela', formatCurrencyAsText(subTotais.valTotNfItem));
      }, 10);
    }
  }, [parcelas, subTotais.valTotNfItem]);

  const { getValues: getValuesFormNfe } = formNfe;

  const validation = useCallback(async () => {
    const cod_pessoa = getValuesFormNfe('cod_pessoa');
    const dataEmissao = getValuesFormNfe('dta_emissao');
    const dataEntrada = getValuesFormNfe('dta_entrada');

    if (cod_pessoa === undefined || cod_pessoa === '') {
      toast.warning('Fornecedor deve ser selecionado');
      return true;
    }
    if (dataEmissao === '' || dataEmissao === undefined) {
      toast.warning('Data de emissão deve ser informada.');
      return true;
    }

    if (dataEntrada === '') {
      toast.warning('Data de entrada deve ser informada');
      return true;
    }

    if (new Date(dataEntrada) < new Date(dataEmissao)) {
      toast.warning(
        'Data de Emissão não deve ser maior que Data de Entrada/Saída.',
      );
      return true;
    }
    if (produtos.length === 0) {
      toast.warning('Item(s) da NF deve(m) ser informado');
      return true;
    }
    return false;
  }, [getValuesFormNfe, produtos]);

  const handleRecalculaFinanceiro = useCallback(async () => {
    if (await validation()) return;

    const fornecedor = getValuesFormNfe('cod_pessoa').value;
    const tipo_condicao = getValuesFormNfe('cod_perfil').tipo_operacao;

    const showNoConditionsAlert = async () => {
      await Swal.fire({
        text: `Nenhuma condição de pagamento localizada para esse parceiro ou perfil.`,
        icon: 'info',
        showConfirmButton: true,
        confirmButtonText: 'OK',
      });
    };

    const createOptions = (data: any[], totalNf: any) => {
      const valParcela = data.length <= 0 ? 0 : Number(totalNf) / data.length;
      const valParcelaAjustado = parseFloat(valParcela.toFixed(2));

      return data.map((item, index) => {
        const uuid = nanoid();
        const { cod_cc, dta_emissao, dta_entrada } = getValuesFormNfe();
        let dta_vencimento = CalcDataCondicao(
          cod_cc,
          dta_emissao,
          dta_entrada,
          item.num_condicao,
          0,
        );

        dta_vencimento = moment(dta_vencimento).format('YYYY-MM-DD');
        const parsedDate = parseISO(dta_vencimento);
        const formattedDate = format(parsedDate, 'yyyy-MM-dd');

        return {
          uuid,
          id: uuid,
          cod_parcela: undefined,
          num_registro: index + 1,
          num_condicao: item.num_condicao,
          cod_condicao: item.cod_condicao,
          des_condicao: item.des_condicao,
          cod_finalizadora: item.cod_finalizadora,
          des_finalizadora: `${item.cod_finalizadora} - ${item.des_finalizadora}`,
          dta_vencimento: formattedDate,
          val_parcela: transformAsCurrency(valParcelaAjustado),
        };
      });
    };

    const updateParcelas = async (options: any) => {
      if (!compareArrays(options, parcelas)) {
        setParcelas([]);
        const parcelasFind = masterDetail.find(
          (item) => item.obj_name === 'parcelas',
        );
        parcelasFind?.itens.insert.forEach((parcela) => {
          deleteItens('parcelas', parcela.uuid, masterDetail, setMasterDetail);
        });
        options.map(async (p: any) => {
          await insertOrUpdate('parcelas', p, masterDetail, setMasterDetail);
        });
      }

      options.sort((a: any, b: any) => a.num_condicao - b.num_condicao);
      setParcelas(options);
    };

    const handleError = (error: any) => {
      const message = error.data?.message || String(error);
      toast.error(message);
    };

    const processConditions = async () => {
      try {
        const { data, success } = await conditions.fetchConditions(
          fornecedor,
          tipo_condicao,
        );

        if (!success) return;
        if (data.length === 0) {
          const {
            cod_perfil: { cod_perfil },
          } = getValuesFormNfe();
          const { data: dataPerfil, success: successPerfil } =
            await conditions.fetchPerfilConditions(cod_perfil);
          if (!successPerfil) return;

          const totalNf = calculateTotalNf(produtos, xmlImportado);
          const options = createOptions(dataPerfil, totalNf);
          if (dataPerfil.length === 0) await showNoConditionsAlert();
          else await updateParcelas(options);
          return;
        }
        const totalNf = calculateTotalNf(produtos, xmlImportado);
        const options = createOptions(data, totalNf);
        await updateParcelas(options);
      } catch (error) {
        handleError(error);
      }
    };

    if (parcelas.length <= 0) {
      await processConditions();
    } else {
      const confirmaRecalculo = await Swal.fire({
        title:
          'Deseja pesquisar novamente as condições de pagamento deste parceiro/perfil?',
        text: 'As parcelas atuais poderão sofrer alteração de prazos, formas de pagamento e valores.',
        showCancelButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => result.isConfirmed);

      if (confirmaRecalculo) {
        await processConditions();
      }
    }
  }, [
    validation,
    getValuesFormNfe,
    xmlImportado,
    masterDetail,
    setMasterDetail,
    setParcelas,
    produtos,
    parcelas,
  ]);

  const calcularDiasEntreDatas = useCallback(
    (dtaVencimento: string, dtaEmissao: string): number => {
      if (!dtaVencimento || !dtaEmissao) return 0;

      const dataVencimento = parseISO(dtaVencimento);
      const dataEmissao = parseISO(dtaEmissao);

      return differenceInDays(dataVencimento, dataEmissao);
    },
    [],
  );

  const validarDatas = useCallback(
    (dtaVencimento: string, dtaEmissao: string): boolean => {
      if (!dtaVencimento || !dtaEmissao) return false;

      const dataVencimento = parseISO(dtaVencimento);
      const dataEmissao = parseISO(dtaEmissao);

      return !isBefore(dataVencimento, dataEmissao);
    },
    [],
  );

  const formatToMonetaryValue = useCallback((input: number | string) => {
    if (typeof input === 'number') return input;

    const cleanedInput = input.replace(/[^0-9.,]/g, '');
    const standardizedInput = cleanedInput.replace(',', '.');
    const withoutLeadingZeros = standardizedInput.replace(/^0+(?!\.)/, '');
    const monetaryValue = parseFloat(withoutLeadingZeros);

    if (Number.isNaN(monetaryValue)) return 0;
    return monetaryValue;
  }, []);

  const handleAddParcela = handleSubmit(async () => {
    setValue('condicao_update', false);
    const uuid = uuidParcela?.uuid === undefined ? nanoid() : uuidParcela?.uuid;
    const { finalizadora, dta_vencimento, val_parcela } = getValues();

    const dataEmissao = getValuesFormNfe('dta_emissao');

    if (!validarDatas(dta_vencimento, dataEmissao)) {
      setError('dta_vencimento', { type: 'required' }, { shouldFocus: true });
      toast.warning(
        'A data de vencimento não pode ser anterior à data de emissão.',
      );
      return;
    }

    const registroDuplicado = parcelas.some(
      (parcela) =>
        parcela.cod_finalizadora === finalizadora.value &&
        parcela.dta_vencimento === dta_vencimento &&
        uuid !== parcela.uuid,
    );

    if (registroDuplicado) {
      const { isConfirmed } = await MySwal.fire({
        title: 'Já existe uma parcela para esse dia e finalizadora.',
        text: 'Deseja editar a parcela já existente?',
        showCancelButton: true,
        confirmButtonColor: '#07289e',
        cancelButtonColor: '#ff7b7b',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar',
      });

      if (!isConfirmed) return;

      const itemDuplicado = parcelas.find(
        (item) =>
          item.cod_finalizadora === finalizadora.value &&
          item.dta_vencimento === dta_vencimento,
      );

      if (itemDuplicado) itemDuplicado.val_parcela = val_parcela;
    }

    const data: Parcela = {
      uuid,
      id: uuid,
      cod_parcela: uuidParcela?.cod_parcela,
      num_registro: parcelas.length + 1,
      cod_condicao: 2,
      des_condicao: 'DD - DIAS DA DATA',
      num_condicao: calcularDiasEntreDatas(dta_vencimento, dataEmissao),
      cod_finalizadora: finalizadora.value,
      des_finalizadora: `${finalizadora.value} - ${finalizadora.label}`,
      dta_vencimento,
      val_parcela: transformAsCurrency(val_parcela),
    };
    const parcelasData: any[] = await insertOrUpdate(
      'parcelas',
      data,
      masterDetail,
      setMasterDetail,
    );

    const othersParcels = parcelas.filter((p) => p.uuid !== uuid);

    const parcels = [...othersParcels, ...parcelasData];

    parcels.forEach((parcel) => {
      deleteItens('parcelas', parcel.uuid, masterDetail, setMasterDetail);
    });

    parcels.sort((a, b) => a.num_condicao - b.num_condicao);

    const seen = new Set<string>();
    const result: any[] = [];

    parcels.forEach((op) => {
      const key = [
        'cod_condicao',
        'cod_finalizadora',
        'cod_parcela',
        'des_condicao',
        'num_condicao',
      ]
        .map((k) => op[k])
        .join('|');

      if (!seen.has(key)) {
        seen.add(key);
        result.push(op);
      }
    });

    const parcelCorrigida = (res: Parcela[]) =>
      res
        .sort(
          (a, b) =>
            new Date(a.dta_vencimento).getTime() -
            new Date(b.dta_vencimento).getTime(),
        )
        .map((p, index) => {
          const desFinalizadora = p.des_finalizadora ?? '';
          const [firstPart, ...restParts] = desFinalizadora.split(' - ');
          const lastPart = restParts.length
            ? restParts[restParts.length - 1]
            : '';
          const description = restParts.length
            ? `${firstPart} - ${lastPart}`
            : firstPart;

          return {
            ...p,
            val_parcela: formatToMonetaryValue(p.val_parcela || 0),
            des_finalizadora: description,
            num_registro: index + 1,
          };
        });

    const parcelaCorrigida = parcelCorrigida(result);

    parcelaCorrigida.forEach(async (p) => {
      await insertOrUpdate('parcelas', p, masterDetail, setMasterDetail);
    });

    setParcelas(parcelaCorrigida);
    setTimeout(() => {
      handleClearForm();

      if (parcelaCorrigida.length !== 0) {
        const totalParcelas = parcelaCorrigida.reduce(
          (acc, parcela: any) => acc + parcela.val_parcela,
          0,
        );

        const resultado =
          subTotais.valTotNfItem - formatToMonetaryValue(totalParcelas);

        setTimeout(() => {
          setValue(
            'val_parcela',
            formatCurrencyAsText(resultado > 0 ? resultado : 0),
          );
        }, 10);
      } else {
        setTimeout(() => {
          setValue('val_parcela', formatCurrencyAsText(subTotais.valTotNfItem));
        }, 10);
      }
    }, 50);
  });

  const handleClearForm = () => {
    setValue('condicao_update', false);
    reset(clearForm);
    setUuidParcela(undefined);
  };

  const refDivBtn = useRef<HTMLDivElement | null>(null);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();

        setTimeout(() => {
          if (refDivBtn.current) {
            const btn: HTMLButtonElement | null =
              refDivBtn.current.querySelector('button[type="button"]');
            focusInputByName('dta_vencimento');
            if (btn) btn.click();
          }
        }, 100);
      }
    },
    [],
  );

  return (
    <>
      <Row>
        <Col md={10} sm={12}>
          <Separator labelText="Financeiro" sidePadding="0 0" />
        </Col>
        <Col md={2} sm={12}>
          <ButtonItens
            disabled={!canUpdate}
            type="button"
            onClick={handleRecalculaFinanceiro}
          >
            <FaSync color="#28a745" />
            <span>(Re) Calcular</span>
          </ButtonItens>
        </Col>
      </Row>
      <Row className="d-flex align-items-center position-relative">
        <Col sm={12} md={4}>
          <InputSelect
            label="Finalizadora"
            maxLength={50}
            placeholder="Selecione..."
            name="finalizadora"
            disabled={!canUpdate}
            register={register}
            isError={!!formState.errors.finalizadora}
            options={finalizadoras}
            control={control}
            changeSelected={(selected: any) => {
              setValue('finalizadora', selected);
            }}
            onKeyDown={handleKeyDown}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputDate
            register={register}
            label="Vencimento"
            name="dta_vencimento"
            control={control}
            disabled={!canUpdate}
            isError={!!formState.errors.dta_vencimento}
            onKeyDown={handleKeyDown}
          />
        </Col>
        <Col md={2} sm={12} onKeyDown={handleKeyDown}>
          <InputMoney
            name="val_parcela"
            register={register}
            control={control}
            isError={!!formState.errors.val_parcela}
            min={0}
            label="Valor"
            disabled={!canUpdate}
            placeholder="0,00"
          />
        </Col>
        <Col
          md={2}
          sm={12}
          ref={refDivBtn}
          style={{ display: 'flex', height: '70px', alignItems: 'flex-end' }}
        >
          <CustomButtonNovo
            onClick={async () => {
              await handleAddParcela();
            }}
            label="Adicionar"
            icon={IoMdAddCircleOutline}
            disabled={!canUpdate}
            variant="confirm"
            width="100%"
          />
        </Col>
        <Col
          md={2}
          sm={12}
          style={{ display: 'flex', height: '70px', alignItems: 'flex-end' }}
        >
          <CustomButtonNovo
            onClick={handleClearForm}
            label="Limpar"
            icon={FaEraser}
            disabled={!canUpdate}
            variant="clear"
            width="100%"
          />
        </Col>
      </Row>
    </>
  );
};
